import { useTitle } from 'react-use';

import bgAnalytics from '../../assets/img/bg-analytics.png';
import { MyExperienceList } from '../../components/Analytics/AnalyticsExperienceList';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { makeTitle } from '../../utils/common';

// eslint-disable-next-line import/no-default-export
export default function Home(): JSX.Element {
  useTitle(makeTitle('Memories'));

  return (
    <PublicHomeLayout>
      <div
        className='relative flex-1 overflow-y-auto scrollbar bg-local bg-no-repeat bg-cover'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${bgAnalytics})`,
        }}
      >
        <MyExperienceList />
      </div>
    </PublicHomeLayout>
  );
}
